<template>
  <div>
    <div class="certificateTop">
      <div class="certificateTopContent">
        <img src="../../assets/image/edumsg.png" alt="">
        <span class="f-l">&nbsp;·&nbsp;</span>
        <p>证书查询</p>
      </div>
    </div>
    <div class="certificateBottom">
      <div class="certificateTitle">
        <h3>证书查询系统</h3>
      </div>
      <div class="certificateInput">
        <div class="ipt_box">
          <input v-model="code" type="text" placeholder="请输入您的身份证号或证书编号">
          <button @click="clickSerch()">立即查询</button>
        </div>
      </div>
      <!-- <div v-show="errMsg" class="errMsg">
        <span>请输入正确的身份证号或证书编号</span>
      </div> -->
      <div v-if="certificateList.length>1" class="searchRes">
        <p class="searchTitle">
          <span>个人证书（{{ certificateList.length }}个）</span>
          <span>操作</span>
        </p>
        <p v-for="(item,index) in certificateList" :key="index">
          <span>{{ item.certificate_name }}</span>
          <span class="check" @click="check(item.url)">查看</span>
        </p>
      </div>
      <el-dialog
        title="提示"
        :visible.sync="errMsg"
        width="30%"
        center
      >
        <span>没有查询到相关筑龙证书</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="errMsg = false">我知道了</el-button>
        </span>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import { get_imgId, get_img, searchCertification } from '@/api/Zs.js'
export default {
  data() {
    return {
      code: '',
      errMsg: false,
      ids: '',
      certificateList: []
    }
  },
  methods: {
    // 查看
    check(url) {
      window.open(url)
    },
    look() {
      var params = {
        id: this.ids,
        type: 'look'
      }
      get_img(params).then(res => {
        console.log(res)
      })
    },
    clickSerch() {
      // console.log(111)
      var params = {
        code: this.code
      }
      // get_imgId(params).then(res => {
      //   if (res.errNo === 0) {
      //     this.ids = res.result
      //     window.open(res.result.url)
      //     // this.look()
      //   } else {
      //     this.errMsg = true
      //   }
      // })
      searchCertification(params).then(res => {
        if (res.errNo === 0) {
          if (res.result.length == 1) {
            this.certificateList.length = 1
            this.$nextTick(() => {
              console.log(res.result[0].url)
              window.open(res.result[0].url, '_blank')
            })
          } else if (res.result.length > 1) {
            this.certificateList = res.result
          }
        } else {
          this.errMsg = true
        }
      })
    },
    // 调整页面的
    changeHeader() {
      const u = navigator.userAgent
      if (u.indexOf(' osazlxs_ ') !== -1) {
        // 在APP里面：包含ios、Android
        // 对APP进行 区分ui，比如 隐藏头部、底部

        // 如果 需要对APP进行区分
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
        if (isiOS) {
          // 在ios里 可以单独对 ios 进行处理

        } else {
          // 在Android里面，对Android的页面进行单独处理

        }
      } else {
        // 非APP，h5  现在咋样 保持不变
      }
    },
    // 假设该方法是 点击调转课程的
    clickLesson() {
      const u = navigator.userAgent
      if (u.indexOf(' osazlxs_ ') !== -1) {
      // if (true) {
        // 需要对APP进行区分，假设APP里方法名为：app_showLessonDetail
        // 如果传参 比如是链接
        const url = ''
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
        if (isiOS) {
          // 在ios里 可以单独对 ios 进行处理
          window.webkit.messageHandlers.app_showLessonDetail.postMessage(url)
        } else {
          // 在Android里面，对Android的页面进行单独处理
          window.android.app_showLessonDetail(url)
        }
      } else {
        // 非APP，h5  现在咋样 保持原调转不变
      }
    }
  }
}
</script>

<style lang="scss" scoped>
input{
  outline: none;
  border: none;
}
.ipt_box{
  padding: 4px 5px 4px 20px;
  display: flex;
  align-items: center;
  border: 1px solid #999;
  border-radius: 40px;
  overflow: hidden;
}
.errMsg{
  text-align: left;
  span{
    padding: 0 0 0 291px;
    color: #ee2e2e;
  }
}
.certificateTitle{
  margin: 150px 0 0 0;
  h3{
    font-size: 22px;
    font-weight: 600;
  }
}
.certificateInput{
  width: 560px;
  margin: 0 auto;
  margin-top: 60px;
  height: 52px;
}
.certificateInput input{
  padding-left: 10px;
  float: left;
    height: 36px;
    // border: 1px solid #b8b8b8;
    border-right: none;
    width: 440px;
}
.certificateInput button{
  border: none;
    width: 119px;
    float: left;
    height: 36px;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    background: #ee2e2e;
    border-radius: 20px;
}
.certificateBottom{
  overflow: hidden;
  width: 1140px;
  height: 765px;
  margin: 0 auto;
  background: #fff;
  margin-top: 20px;
}
.certificateTop{
  width: 100%;
  height: 50px;
  background: #909090;
}
.certificateTopContent{
  width: 1140px;
  height: 100%;
  margin: 0 auto;
  text-align: left;
  display: flex;
  align-items: center;
  img{
    margin: 0 10px 0 5px;
  }
  .f-l{
    color: #ddd;
    font-size: 18px;
  }
  p{
    color: #ddd;
    display: inline-block;
    font-size: 18px;
    padding: 0 0 0 10px;
  }
}
/deep/.el-dialog__title{
  font-size: 22px;
  font-weight: 600;
}
/deep/.el-dialog__body{
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
}
/deep/.el-button--primary{
  letter-spacing: 2px;
}
.searchRes{
  margin:40px auto;
  width: 600px;
  .searchTitle{
    font-size: 18px;
    font-weight: 800;
  }
  p{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom:20px;
    font-size: 14px;
    .check{
      width: 36px;
      text-align: center;
      color:#ee2e2e;
      cursor:pointer;
    }
  }
}
</style>
